import React from "react";
import Lottie from "lottie-react";
import NotFound from "../../src/animation/404-page.json";
import { Link } from "react-router-dom";
import GreenLoader from "../../src/animation/blinker.json";

const PageNotFound = () => {
  return (
    <>
      <section>
        <div className="privacy-wrapper">
          <div className="common-head-wrap">
            <h1>Page Not Found !</h1>
          </div>
          <div className="mid-content-wrap scale-business">
            <div className="container">
              <div class="lottie-wrapper">
                <Lottie
                  autoPlay="true"
                  loop="true"
                  className="not-found-img"
                  animationData={NotFound}
                />
              </div>
             
              <div className="text-center button-mtop">
                <Link to="/" className="Back-to-home start-driving-btn">
                  <Lottie
                    autoPlay="true"
                    loop="true"
                    className="glow-icon"
                    animationData={GreenLoader}
                  />
                  Back to Homepage
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PageNotFound;
