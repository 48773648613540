import React, { Suspense } from "react";
import Footer from "../components/shared/desktop/footer/Footer";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "../components/shared/desktop/header/Header";
import { isMobile, isTablet } from "react-device-detect";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import PageNotFound from "../pages/404";
const HomeMobile = React.lazy(() => import("../pages/mobile/home/index"));
const HeaderMobile = React.lazy(() =>
  import("../../src/components/shared/mobile/header/Header")
);
const FooterMobile = React.lazy(() =>
  import("../../src/components/shared/mobile/footer/Footer")
);
const Home = React.lazy(() => import("../pages/desktop/home/index"));
const BookDemo = React.lazy(() => import("../pages/desktop/bookDemo/index"));
const BookDemoMobile = React.lazy(() => import("../pages/mobile/bookDemo/index"));

function Root() {
  return (
    <>
      <div className="App">
        <BrowserRouter>
          {isMobile ? <HeaderMobile /> : <Header />}
          <Suspense fallback={<div className="page-loader" />}>
            <Routes>
              {isTablet ? (
                <>
                <Route path="/" element={<Home />} />
                <Route path="/book-a-demo" element={<BookDemo />} />
                </>
              ) : isMobile ? (
                <>
                <Route path="/" element={<HomeMobile />} />
                <Route path="/book-a-demo" element={<BookDemoMobile />} />
                </>
              ) : (
                <>
                <Route path="/" element={<Home />} />
                <Route path="/book-a-demo" element={<BookDemo />} />
                </>
              )}
              <Route path="/privacypolicy" element={<PrivacyPolicy />} />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </Suspense>
          {isTablet ? <Footer /> : isMobile ? <FooterMobile /> : <Footer />}
        </BrowserRouter>
      </div>
    </>
  );
}

export default Root;
