import React, { useState } from "react";
import { Formik } from "formik";
import ReCaptcha from "react-google-recaptcha";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import "/node_modules/react-progress-button/react-progress-button.css";
import axios from "axios";

const ContactForm = ({ modalTestEmail, setModalTestEmail }) => {
  const [isSubmit, setIsSubmit] = useState(false);

  const [loader, setLoader] = useState(false);

  return (
    <>
      <section>
        <Modal
          isOpen={modalTestEmail}
          className="pop-up-full-box"
          toggle={() => setModalTestEmail(!modalTestEmail)}
          size="lg"
        >
          <ModalHeader>
            <div className="add-user-heading">
              <h5>Contact us</h5>
            </div>
            <div className="cross-button">
              <Button
                color="secondary"
                onClick={() => setModalTestEmail(false)}
              >
                <FontAwesomeIcon icon={faXmark} />
              </Button>
            </div>
          </ModalHeader>
          <ModalBody>
            <div className="contact-wrap">
              <Formik
                initialValues={{
                  firstname: "",
                  lastname: "",
                  email: "",
                  number: "",
                  message: "",
                  recaptcha: "",
                }}
                validate={(values) => {
                  const errors = {};

                  if (!values.firstname) {
                    errors.firstname = "Required";
                  } else if (values.firstname === "") {
                    errors.firstname = "Must be filled";
                  }

                  if (!values.lastname) {
                    errors.lastname = "Required";
                  } else if (values.lastname === "") {
                    errors.lastname = "Must be filled";
                  }

                  if (!values.email) {
                    errors.email = "Required";
                  } else if (
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                      values.email
                    )
                  ) {
                    errors.email = "Invalid email address";
                  }

                  if (!values.number) {
                    errors.number = "Required";
                  } else if (
                    !/^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/i.test(
                      values.number
                    )
                  ) {
                    errors.number = "Invalid Mobile Number";
                  }

                  if (!values.recaptcha) {
                    errors.recaptcha = "Please validate the CAPTCHA";
                  }
                  return errors;
                }}
                onSubmit={async (values, { setSubmitting }) => {
                  // setTimeout(() => {
                  //   setIsSubmit(true);
                  //   setLoader(false);
                  // }, 5000);
                  setLoader(true);

                  const response = await axios.post(
                    "https://415tzn1sgc.execute-api.ap-south-1.amazonaws.com/dev/dev",
                    JSON.stringify({
                      first_name: values.firstname,
                      last_name: values.lastname,
                      email: values.email,
                      phone: values.number,
                      message: values.message,
                    })
                  );

                  if (response?.data?.statusCode === 200) {
                    setIsSubmit(true);
                    setLoader(false);
                    values.firstname = "";
                    values.lastname = "";
                    values.email = "";
                    values.number = "";
                    values.message = "";
                  } else {
                    setLoader(false);
                  }

                  setTimeout(() => {
                    setSubmitting(false);
                    setIsSubmit(false);
                  }, 4000);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-md-6 col-sm-6 col-6">
                        <div className="form-group">
                          <label>
                            First Name <span className="red-mandatory">*</span>
                          </label>
                          <input
                            type="text"
                            placeholder="Enter here"
                            name="firstname"
                            className="form-control"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.firstname}
                          />
                          <span className="error-msg">
                            {errors.firstname &&
                              touched.firstname &&
                              errors.firstname}
                          </span>
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-6 col-6">
                        <div className="form-group">
                          <label>
                            Last Name <span className="red-mandatory">*</span>
                          </label>
                          <input
                            type="text"
                            placeholder="Enter here"
                            name="lastname"
                            className="form-control"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.lastname}
                          />
                          <span className="error-msg">
                            {errors.lastname &&
                              touched.lastname &&
                              errors.lastname}
                          </span>
                        </div>
                      </div>
                      <div className="col-md-6  col-sm-6">
                        <div className="form-group">
                          <label>
                            Email <span className="red-mandatory">*</span>
                          </label>
                          <input
                            type="email"
                            placeholder="Enter here"
                            className="form-control"
                            name="email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                          />
                          <span className="error-msg">
                            {errors.email && touched.email && errors.email}
                          </span>
                        </div>
                      </div>
                      <div className="col-md-6  col-sm-6">
                        <div className="form-group">
                          <label>
                            Mobile <span className="red-mandatory">*</span>
                          </label>
                          <input
                            type="text"
                            placeholder="Enter here"
                            className="form-control"
                            name="number"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.number}
                          />
                          <span className="error-msg">
                            {errors.number && touched.number && errors.number}
                          </span>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Message</label>
                          <textarea
                            placeholder="Enter here"
                            rows="4"
                            className="form-control"
                            name="message"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.message}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <ReCaptcha
                          sitekey={process.env.REACT_APP_SITE_KEY}
                          onChange={(response) => {
                            setFieldValue("recaptcha", response);
                          }}
                        />
                        <span className="error-msg">
                          {errors.recaptcha &&
                            touched.recaptcha &&
                            errors.recaptcha}
                        </span>
                      </div>
                    </div>
                    {isSubmit ? (
                      <div className="thankyou-msg">
                        <p>
                          Thanks for contacting us! We will be in touch with you
                          shortly.
                        </p>
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="two-btn-wrap text-center">
                      <div className="cust-button-wrap contact-form-wrap">
                        <button type="submit">
                          {!loader ? <span>Submit</span> : ""}
                          {loader ? (
                            <div class="loader" id="loader-4">
                              <span></span>
                              <span></span>
                              <span></span>
                            </div>
                          ) : (
                            ""
                          )}
                        </button>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </ModalBody>
        </Modal>
      </section>
    </>
  );
};

export default ContactForm;
